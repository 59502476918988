<template>
    <section id="Manage">
        <div class="card card--form" v-if="Object.keys(books).length === 0">
            <h3>So you want to be a bookie?</h3>
            <p>It doesn't look like you've created any books on your account. If you're known as the bookie of your group, this is the spot for you.</p>
            <p>Press the button below to create your first book.</p>
            <router-link :to="{ name: 'CreateBook' }">
                <Button arrow>Create Book</Button>
            </router-link>
        </div>
        <div class="card" v-for="book in Object.entries(books)" :key="book[0]">
            <div class="content">
                <div class="name">
                    <h3>{{ book[1].name }}</h3>
                    <Status :type="book[1].open ? 'open' : 'closed'" />
                </div>
                <div class="members">
                    <h4>Members</h4>
                    <h4 class="member-count">{{ book[1].memberCount }}</h4>
                </div>
            </div>
            <router-link :to="{ name: 'Members', params: { book_id: book[0] } }">
                <button class="manage">Manage</button>
            </router-link>
        </div>
        <router-link :to="{ name: 'CreateBook' }" v-if="Object.keys(books).length > 0">
            <Floater icon="new" />
        </router-link>
    </section>
</template>

<script>

    // Components.
    import Button from '@/components/Button.vue';
    import Status from '@/components/Status.vue';
    import Floater from '@/components/Floater.vue';

    // Firebase.
    import { watchBook } from '@/firebase/books.js';

    export default {
        name: 'Manage',
        components: {
            Button,
            Status,
            Floater,
        },
        data() {
            return {
                books: {},
            }
        },
        mounted() {
            this.$store.dispatch('setNavShadow', false);
        },
        created() {

            let books = [];

            if (this.profile.books) {
                books = Object.entries(this.profile.books).filter(book => (book[1].membership === 'bookie' || book[1].membership === 'admin'));
            }

            if (books.length > 0) {
                books.map(book => {

                    this.$store.dispatch('updateLoading', 1);

                    watchBook(book[0]).on('value', snapshot => {

                        const val = snapshot.val();
                        let memberCount = val.members ? Object.keys(val.members).length + 1 : 1;

                        this.books = { ...this.books, [book[0]]: { ...val, memberCount } };
                        this.$store.dispatch('updateLoading', -1);

                    });

                });
            }

        },
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .card--form {
        margin: size(Medium) auto;
    }

    .card--form p {
        margin-top: size(Small);
    }

    .card--form .Button {
        margin-top: size(Large);
    }

    .card:not(.card--form):first-of-type {
        margin-top: size(Medium);
    }

    .card:not(.card--form) {
        margin-top: size(Small);
    }

    .card:not(.card--form):last-of-type {
        margin-bottom: size(XXLarge);
    }

    .content {
        padding: size(Medium);
    }

    .name, .members {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .name {
        padding-bottom: size(Medium);
        border-bottom: 1px solid color(Emperor, 0.15);
    }

    .members {
        padding-top: size(Medium);
    }

    .member-count {
        color: color(Emperor, 0.25);
    }

    button.manage {

        appearance: none;
        outline: none;
        border: none;

        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 12px;
        color: color(OldGold);
        text-transform: uppercase;

        background: color(White);

        width: 100%;
        padding: size(Medium);

        display: flex;
        align-items: center;
        justify-content: center;

        border-top: 1px dashed color(Emperor, 0.15);

    }

    .Floater {
        position: fixed !important;
        bottom: size(Medium);
        right: size(Medium);
        z-index: 5;
    }

</style>
